<template>
  <div class="PageWrapper">
    <AuthChecker></AuthChecker>

    <Sidebar
      :isUserSidebar="
        this?.user?.parent_company == null ||
        this?.user?.parent_company.length == 0
          ? true
          : false
      "
    />
    <div class="PageContent">
      <div class="PageTitle">
        <span class="OctaS_PageTitleDefault"
          >Учебные заведения, студенты которых нам доверяют</span
        >
      </div>
      <div class="universities">
        <div v-for="uni in universities" :key="uni.id" class="university-card">
          <div class="university-card-main-part">
            <div class="uni-logo">
              <img :src="uni.img" />
            </div>
            <div class="uni-info">
              <span class="uni-title" lang="ru">{{ uni.fullName }}</span>
              <span class="uni-region" lang="ru">{{ uni.region_name }}</span>
            </div>
          </div>
          <div class="uni-description" lang="ru">
            {{ uni.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Profile/Sidebar.vue";

import AuthChecker from "@/components/CheckUserAuthorized";

import { getRegionAndUniverList } from "@/api/user.js";
import { mapGetters } from "vuex";

export default {
  name: "PartnersUniversities",
  components: { Sidebar, AuthChecker },
  data() {
    return {
      universities: [],
    };
  },
  async created() {
    await getRegionAndUniverList().then((res) => {
      res.data.forEach((region) => {
        region.univers.forEach((uni) => {
          if (
            region.name !== "Другое" &&
            uni.name !== "Другое" &&
            uni.img !== null &&
            uni.img !== ""
          ) {
            uni.region_name = region.name;
            this.universities.push(uni);
          }
        });
      });
    });
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),
  },
};
</script>

<style scoped>
.PageWrapper {
  position: relative;
  width: 100%;
  height: 100vh;

  top: 0%;
  left: 0%;

  margin: 0%;
  padding: 0%;

  display: flex;
  flex-direction: row;

  overflow: hidden;

  background-image: radial-gradient(
    123.22% 129.67% at 100.89% -5.6%,
    #201d47 0%,
    #17153a 100%
  );
  background-position: 50% 50%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: auto 120%;
}

.PageContent {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  margin: 0% auto;
  padding: 23px 32px 23px 21px;

  overflow-y: auto;
}

.PageTitle {
  position: relative;
  display: block;

  color: #e8e7ec;
  font-family: "Montserrat";
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.universities {
  width: calc(100% - 1rem);

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 3fr));

  grid-auto-flow: dense;
  grid-row-gap: 2.5rem;
  grid-column-gap: 2.5rem;

  margin-bottom: 5rem;

  margin: 2.5rem 0;
}

.university-card:hover {
  cursor: pointer;
}

.university-card {
  position: relative;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2rem;

  border-radius: var(--o-s-global-border-radius);
  background: rgba(32, 30, 72, 0.7);

  /* shadow */
  box-shadow: 8px 0px 8px 0px rgba(31, 18, 35, 0.15);
}

.university-card-main-part {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 1rem;

  width: 100%;
  height: fit-content;
}

.uni-logo {
  width: 10rem;
  height: 10rem;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;

  border-radius: 1rem;
}

.uni-logo img {
  max-width: 90%;
  max-height: 80%;

  aspect-ratio: auto;
}

.uni-info {
  position: relative;
  display: flex;
  flex-direction: column;

  height: 100%;

  line-height: 1.1875rem;
  letter-spacing: -0.0175rem;

  hyphens: none;
}

.uni-title {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  hyphens: auto;

  color: #e8e7ec;
  font-family: "Montserrat";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-bottom: 1rem;
}

.uni-region {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  hyphens: auto;

  overflow: hidden;
  color: #c8c3de;
  font-family: "Montserrat";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.uni-description {
  overflow: hidden;
  color: #c8c3de;

  padding-right: 10px;

  /* text */
  font-family: "Montserrat";
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;

  max-height: 7rem;
  overflow-y: scroll;
  width: 100%;

  hyphens: auto;
  text-align: justify;
}
</style>

<style scoped>
@media (max-width: 500px) {
  .university-card-main-part {
    flex-direction: column-reverse;
  }

  .uni-logo {
    width: 50%;
    margin: 2rem auto 0rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 480px) {
  .PageTitle {
    width: calc(100% - 1rem);
    padding-left: 1rem;
  }

  .universities {
    grid-template-columns: repeat(auto-fill, minmax(320px, 3fr));
    margin-left: auto;
    margin-right: auto;
  }
  .university-card {
    padding: 2rem 0.5rem;
    margin: 0.5rem;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .PageTitle {
    width: calc(100% - 1rem);
    padding-left: 1rem;
  }

  .universities {
    padding-left: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .PageTitle {
    width: calc(100% - 1rem);
    padding-left: 1rem;
  }

  .universities {
    padding-left: 1rem;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .PageTitle {
    width: calc(100% - 1rem);
    padding-left: 1rem;
  }

  .universities {
    padding-left: 1rem;
  }
}
</style>
